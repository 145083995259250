import { FC } from 'react';
import Badges from '../generics/badge';
import styles from './badge-grid.module.scss';

const data: { name: string; url?: string }[][] = [
  [
    {
      name: 'web3app.app',
      url: 'https://web3app.app/',
    },
    {
      name: 'wizapp.io',
      url: 'https://wizapp.io/',
    },
    {
      name: 'eventswiz.io',
      url: 'https://dev.eventswiz.io/',
    },
  ],
];

const BadgeGrid: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        {data.map((rows, index) => (
          <div key={index} className={styles.row}>
            {rows.map((item, index) => (
              <Badges
                bordered
                rounded
                circlePrefix
                key={`${item.name}-${index}`}
                url={item.url}
              >
                {item.name}
              </Badges>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BadgeGrid;
