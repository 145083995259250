import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

interface CardProps extends PropsWithChildren {
  className?: string;
}

const Card: FC<CardProps> = (props) => {
  const { className, children } = props;

  return (
    <div className={classNames('card', className)}>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Card;
