import { PostOrPage } from '@tryghost/content-api';
import { FC } from 'react';
import Image from 'next/image';

import Card from '../card';
import { IMAGE_PLACEHOLDER } from '@/shared/common';

interface IconCardProps {
  post: PostOrPage;
}

const IconCard: FC<IconCardProps> = (props) => {
  const { post } = props;

  return (
    <Card className="card-words">
      <div className="card-icon">
        {post.feature_image ? (
          <Image
            src={post.feature_image}
            alt={post.feature_image_alt || 'image'}
            title={post.title}
            fill
            style={{ objectFit: 'contain' }}
            loading="lazy"
            blurDataURL={IMAGE_PLACEHOLDER}
          />
        ) : null}
      </div>
      <div className="fs-4 fw-bolder mb-1">{post.title}</div>
      {post.html ? (
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      ) : null}
    </Card>
  );
};

export default IconCard;
