import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import styles from './badge.module.scss';

interface BadgesProps extends PropsWithChildren {
  rounded?: boolean;
  bordered?: boolean;
  circlePrefix?: boolean;
  url?: string;
}

const Badges: FC<BadgesProps> = (props) => {
  const { rounded, bordered, circlePrefix, url, children } = props;

  const classes = classNames(styles.container, 'badge', {
    'rounded-pill': rounded,
    [styles['--bordered']]: bordered,
    [styles['--circle-prefix']]: circlePrefix,
    [styles['--cursor']]: !!url,
  });

  if (url) {
    return (
      <a className={classes} href={url} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return <span className={classes}>{children}</span>;
};

export default Badges;
