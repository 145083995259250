import { InferGetStaticPropsType } from 'next';
import Image from 'next/image';
import { useEffect } from 'react';

import BadgeGrid from '@/components/BadgeGrid';
import Button from '@/components/generics/button';
import Card from '@/components/generics/card';
import FullBleedBackground from '@/components/generics/full-bleed-background';
import IconCard from '@/components/generics/icon-card';
import TextHighLight from '@/components/generics/text-highlight';
import { useLayoutContext } from '@/context/LayoutContext';
import PostModel from '@/models/PostModel';
import { HOME_TAGS } from '@/types/ghost.d';
import { isValidHttpUrl } from '@/utils/url-utils';

export const getStaticProps = async () => {
  const heroPost =
    (
      await PostModel.find({
        filter: [`tags:${HOME_TAGS.HOME}+tags:${HOME_TAGS.HERO}`],
        limit: 1,
      })
    )?.[0] || null;
  const workPosts = await PostModel.find({
    filter: [`tags:${HOME_TAGS.HOME}+tags:${HOME_TAGS.WORKS}`],
    limit: 'all',
  });
  const techPosts = await PostModel.find({
    filter: [`tags:${HOME_TAGS.HOME}+tags:${HOME_TAGS.TECH}`],
    limit: 'all',
  });
  const technologiesPosts = await PostModel.find({
    filter: [`tags:${HOME_TAGS.HOME}+tags:${HOME_TAGS.TECHNOLOGIES}`],
    limit: 'all',
  });
  const leadershipPosts = await PostModel.find({
    filter: [`tags:${HOME_TAGS.HOME}+tags:${HOME_TAGS.LEADERSHIP}`],
    limit: 'all',
  });
  const techNOpsPosts = await PostModel.find({
    filter: [`tags:${HOME_TAGS.HOME}+tags:${HOME_TAGS.TECHANDOPS}`],
    limit: 'all',
  });
  const learnMoreTag = await PostModel.findTagBySlug(HOME_TAGS.LEARNMORE, {});

  return {
    props: {
      heroPost,
      workPosts,
      techPosts,
      technologiesPosts,
      leadershipPosts,
      techNOpsPosts,
      learnMoreTag,
    },
  };
};

export default function Home(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  const {
    heroPost,
    workPosts,
    techPosts,
    leadershipPosts,
    techNOpsPosts,
    learnMoreTag,
  } = props;

  const { setShowStayUpdatedForm } = useLayoutContext();

  useEffect(() => {
    setShowStayUpdatedForm?.(true);

    return () => {
      setShowStayUpdatedForm?.(false);
    };
  }, []);

  return (
    <>
      <section className="eec-home--hero ">
        <FullBleedBackground />
        <div className="row d-flex align-items-center">
          <div className="col-lg-6 hero-left">
            <h1 className="fw-bold mb-4">
              We build and design&nbsp;
              <TextHighLight>Web3</TextHighLight> technologies.
            </h1>
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: heroPost.html || '' }}
            />
            <Button
              onClick={() => {
                if (learnMoreTag && isValidHttpUrl(learnMoreTag.description)) {
                  window.open(learnMoreTag.description!);
                }
              }}
            >
              Learn More
            </Button>
          </div>

          <div className="col-lg-6 hero-right">
            {heroPost.feature_image ? (
              <Image
                className="rounded-circle"
                src={heroPost.feature_image}
                alt={heroPost.feature_image_alt || 'image'}
                title={heroPost.title}
                fill
                style={{ objectFit: 'cover' }}
                loading="lazy"
              />
            ) : null}
          </div>
        </div>
      </section>

      <section className="eec-home--our-works mb-5">
        <h1 className="fw-bold mb-4">
          Our <TextHighLight>Works</TextHighLight>
        </h1>

        <div className="our-works--grid">
          {workPosts?.map((post) => (
            <IconCard key={post.id} post={post} />
          ))}
        </div>
      </section>

      <section className="mb-5">
        <Card>
          <h1 className="fw-bold mb-4">
            Our <TextHighLight>Technologies</TextHighLight>
          </h1>
          {techPosts?.map((post) =>
            post.html ? (
              <div
                key={post.id}
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            ) : null
          )}
          <div className="d-flex align-items-center justify-content-evenly flex-wrap py-3 font-12">
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_html.svg"
                  alt="HTML"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>HTML</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_nodejs.svg"
                  alt="NodeJS"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>NodeJS</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_java.svg"
                  alt="Java"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>Java</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_react.svg"
                  alt="React"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>React</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_css3.svg"
                  alt="CSS"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>CSS</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_cpp.svg"
                  alt="C++"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>C++</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_python.svg"
                  alt="Python"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>Python</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_js.svg"
                  alt="JavaScript"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>JavaScript</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_swift.svg"
                  alt="Swift"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>Swift</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_vuejs.svg"
                  alt="VueJS"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>VueJS</span>
            </div>
            <div className="d-flex flex-column align-items-center mx-2 mb-3 mb-lg-0">
              <div className="eec-home--technology-icon position-relative">
                <Image
                  src="/assets/images/icon_tech_gcloud.svg"
                  alt="GCloud"
                  fill
                  style={{ objectFit: 'contain' }}
                  loading="lazy"
                />
              </div>
              <span>GCloud</span>
            </div>
          </div>
        </Card>
      </section>

      <section className="mb-5">
        <h1 className="fw-bold mb-4">
          <TextHighLight>Ecosystem</TextHighLight>
        </h1>
        <BadgeGrid />
      </section>

      <section className="eec-home--our-leadership mb-5">
        <h1 className="fw-bold mb-4">
          Our <TextHighLight>Leadership</TextHighLight>
        </h1>
        <div className="our-leadership--grid">
          {leadershipPosts?.map((post) => (
            <Card key={post.id}>
              <div className="leader-avatar">
                {post.feature_image ? (
                  <Image
                    className="rounded-circle"
                    src={post.feature_image}
                    alt={post.feature_image_alt || 'image'}
                    title={post.title}
                    fill
                    style={{ objectFit: 'cover' }}
                    loading="lazy"
                  />
                ) : null}
              </div>
              <div className="leader-name">{post.title}</div>
              <div
                className="leader-description"
                dangerouslySetInnerHTML={{ __html: post.html || '' }}
              />
            </Card>
          ))}
        </div>
      </section>

      <section className="eec-home--our-leadership mb-5">
        <h1 className="fw-bold mb-4">
          Our <TextHighLight>Technical and Operations team</TextHighLight>
        </h1>
        <div className="our-leadership--grid">
          {techNOpsPosts?.map((post) => (
            <Card key={post.id}>
              <div className="leader-avatar">
                {post.feature_image ? (
                  <Image
                    className="rounded-circle"
                    src={post.feature_image}
                    alt={post.feature_image_alt || 'image'}
                    title={post.title}
                    fill
                    style={{ objectFit: 'cover' }}
                    loading="lazy"
                  />
                ) : null}
              </div>
              <div className="leader-name">{post.title}</div>
              <div
                className="leader-description"
                dangerouslySetInnerHTML={{ __html: post.html || '' }}
              />
            </Card>
          ))}
        </div>
      </section>
    </>
  );
}
